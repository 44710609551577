import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="NASSCOM Embedded Software Bootcamp: Making Waves in Media"
        paragraph="Discover how NASSCOM's Embedded Software Bootcamp Training Program has captured media attention, shaping the narrative of tech talent development.        "
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/media-coverage/nasscom_1.png" alt="" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont">
                          <h4 className="extra-title">
                          NASSCOM Bootcamp Embedded Software Training Program
                          </h4>
                          <div className="spacial">
                            <p>
                            Elfonze is forging ahead with impactful initiatives to empower talent and expand its reach. From launching embedded training programs to partnering with NASSCOM for a boot camp on embedded software training, the company is fostering skill development and addressing industry needs head-on
                            </p>
                          </div>
                          <h6>- EFY</h6>
                          <p>
                            <a target="_blank" href="https://www.electronicsb2b.com/industry-buzz/elfonze-tech-unveils-game-changing-bootcamp-for-electronics-leaders/">
                            https://www.electronicsb2b.com/industry-buzz/elfonze-tech-unveils-game-changing-bootcamp-for-electronics-leaders/
                            </a>
                          </p>
                          <h6>- ELE Times</h6>
                          <p>
                            <a target="_blank" href="https://www.eletimes.com/elfonze-technologies-launches-the-pioneering-embedded-software-developer-bootcamp-certification-program-of-ssc-nasscom-to-empower-students-in-the-expanding-electronics-industry">
                            https://www.eletimes.com/elfonze-technologies-launches-the-pioneering-embedded-software-developer-bootcamp-certification-program-of-ssc-nasscom-to-empower-students-in-the-expanding-electronics-industry
                            </a>
                          </p>
                          <h6>- MOTORINDIA </h6>
                          <p>
                            <a target="_blank" href="https://www.linkedin.com/posts/motorindia-magazine_elfonze-technologies-launches-revolutionary-activity-7095994615014559744-gKGp?utm_source=share&utm_medium=member_desktop">
                            https://www.linkedin.com/posts/motorindia-magazine_elfonze-technologies-launches-revolutionary-activity-7095994615014559744-gKGp?utm_source=share&utm_medium=member_desktop

                            </a>
                          </p>
                          <h6>- BISINFOTECH </h6>
                          <p>
                            <a target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:7094270613829169152?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7094270613829169152%29">
                            https://www.linkedin.com/feed/update/urn:li:activity:7094270613829169152?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7094270613829169152%29

                            </a>
                          </p>
                          <h6>- EV MECHANICA </h6>
                          <p>
                            <a target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:7094277136751980544?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7094277136751980544%29https://www.linkedin.com/posts/motorindia-magazine_elfonze-technologies-launches-revolutionary-activity-7095994615014559744-gKGp?utm_source=share&utm_medium=member_desktop">
                            https://www.linkedin.com/feed/update/urn:li:activity:7094277136751980544?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7094277136751980544%29https://www.linkedin.com/posts/motorindia-magazine_elfonze-technologies-launches-revolutionary-activity-7095994615014559744-gKGp?utm_source=share&utm_medium=member_desktop

                            </a>
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/media-coverage/nasscom_2.png" alt="" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/media-coverage/nasscom_3.png" alt="" />
                              </div>
                            </div>
                          </div>
                         
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              {/* <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                              <a href="#0">ThemesCamp</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Bhubaneswar</title>
      <meta key="description" name="description" 
        content="Elfonze Technologies joins the Juggernaut in Bhubaneswar!" />
    </>
  )
}

export default BlogDetailsDark;
